import React, { useEffect, useState } from "react";

const AmazonConnectCCP = ({ setResponseMessage }) => {
    const [isValue, setIsValue] = useState(true);

    useEffect(() => {
        const containerDiv = document.getElementById("containerDiv");

        if (window.connect) {
            window.connect.core.initCCP(containerDiv, {
                ccpUrl: "https://herdsectestingmin.my.connect.aws/ccp-v2/",
                loginPopup: true,
                softphone: {
                    allowFramedSoftphone: true,
                },
                loginOptions: {
                    autoClose: true,
                    height: 600,
                    width: 400,
                    top: 0,
                    left: 0
                },
                region: 'us-west-2',
            });

            window.connect.contact((contact) => {
                console.log("🚀 Contact event received:", contact)

                contact.onIncoming(() => {
                    console.log("Incoming call...");
                });

                contact.onRefresh(() => {
                    console.log("Refreshing...");

                    const attributeMap = contact.getAttributes();
                    const contactState = contact?.getStatus()?.type;
                    console.log("Contact State:", contactState);

                    if (contactState !== "ended" && isValue && attributeMap?.LambdaPrediction?.value) {
                        console.log("🚀 Refresh event - Showing alert");
                        setResponseMessage(attributeMap?.LambdaPrediction?.value)
                        setIsValue(false);
                    }
                });

                contact.onAccepted(() => {
                    console.log("Call accepted...");
                    setIsValue(false);
                });

                contact.onEnded(() => {
                    console.log("Call ended - Resetting isValue...");
                    setIsValue(false);
                    setResponseMessage('');
                });

                contact.onConnected(() => {
                    console.log("Call connected...");
                    setIsValue(false);
                });
            });
        }
    }, []);

    return (
        <div id="containerDiv" style={{ height: "500px", border: "1px solid #ccc" }}>
            {
                !window.connect && "Loading..."
            }
        </div>
    );
};

export default AmazonConnectCCP;
