import { useRef, useState } from "react";
import endPoints from "../../../redux/constants";
import axios from "../../../redux/http";

const Modal = ({ audioAnalysisResult, isLoading, errorMsg, onClick }) => {
  console.log("🚀 ~ Modal ~ audioAnalysisResult:", audioAnalysisResult)
  return (
    <div className="popup">
      <div className="modal-content">
        {isLoading ? (
          <div className="main-loading" style={{ height: "20vh" }}>
            <span className="loader"></span>
          </div>
        ) : (
          <div className="main-result">
            <div className="popup-heading">
              <span>
                {errorMsg?.length > 0 ? "Error" : "Audio Analysis Result"}
              </span>
              <button onClick={onClick}>X</button>
            </div>

            <div className="result">
              <span
                style={{
                  color: errorMsg?.length > 0 ? "red" : "",
                  fontSize: "16px",
                }}
              >
                {audioAnalysisResult?.length > 0
                  ? audioAnalysisResult
                  : errorMsg}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const AudioAnalyzer = () => {
   //states
   const [isRecording, setIsRecording] = useState(false);
   const [audioUrl, setAudioUrl] = useState(null);
   //reference
   const mediaRecorderRef = useRef(null);
   const audioChunksRef = useRef([]);
   const streamRef = useRef(null); // Reference to the media stream
   const [voice, setVoice] = useState("");
  //  const [isMusic, setIsMusic] = useState(false);
   const [contentType, setContentType] = useState("music");
   const [formData, setFormData] = useState();
   const [showModal, setShowModal] = useState(false);
   const [audioAnalysisResult, setAudioAnalysisResult] = useState("");
   const [errorMsg, setErrorMsg] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const [isBigFile, setIsBigFile] = useState();
 
   // Handel start recording
   const handleStartRecording = async () => {
     setAudioUrl("");
     setVoice("");
     try {
       // Request access to the microphone
       const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
       streamRef.current = stream; // Store the stream reference
 
       // Create a new MediaRecorder instance
       const mediaRecorder = new MediaRecorder(stream);
       mediaRecorderRef.current = mediaRecorder;
 
       // Start recording
       mediaRecorder.start();
       setIsRecording(true);
 
       // Collect audio data
       mediaRecorder.ondataavailable = (event) => {
         audioChunksRef.current.push(event.data);
       };
 
       // Handle the end of the recording
       mediaRecorder.onstop = () => {
         const audioBlob = new Blob(audioChunksRef.current, {
           type: "audio/mp3",
         });
         const audioUrl = URL.createObjectURL(audioBlob);
         let audio = audioUrl.split("3000/");
         setAudioUrl(audioUrl);
         let audioFile = new File([audioBlob], audio[1] + ".mp3", {
           type: "audio/mpeg",
         });
         setFormData(audioFile);
         setContentType("voice");
         mediaRecorder.current = null;
       };
       setTimeout(() => {
         handleStopRecording();
       }, 11000);
 
       //stop recording after ten seconds
     } catch (err) {
       console.error("Error accessing the microphone:", err);
     }
   };
   // Handel stop recording
   const handleStopRecording = (stop) => {
     if (mediaRecorderRef.current) {
       setIsRecording(false);
       mediaRecorderRef.current.stop();
 
       // Stop all tracks of the media stream
       if (streamRef.current) {
         setVoice("");
         streamRef.current.getTracks().forEach((track) => track.stop());
         streamRef.current = null; // Clear the stream reference
       }
     }
   };
  //  // handel content type
  //  const handelContentTypeChange = (e) => {
  //    handleStopRecording("stop");
  //    setIsMusic(e.target.checked);
  //    setVoice("");
  //    setAudioUrl("");
  //    setContentType("music");
  //  };
   // handel file onChange
   const handelFileChange = (e) => {
     console.log(
       "🚀 ~ handelFileChange ~ e.target?.files[0]?.size:",
       e.target?.files[0]
     );
     if (e.target?.files[0]?.size > 10485760) {
       setVoice("");
       setFormData("");
       setIsBigFile(true);
     } else {
       setVoice(e.target.files[0]?.name);
       setFormData(e.target.files[0]);
     }
   };
   // handel upload/sumbit audio
   const handelUploadAudio = () => {
     let formDataPayload = new FormData();
     formDataPayload.append("audio_file", formData);
     formDataPayload.append("audio_type", "music");
     console.log("formdata", formDataPayload);
     setIsLoading(true);
     axios
       .post(endPoints.analyzeAudio, formDataPayload)
       .then((response) => {
         setIsLoading(false);
         setAudioAnalysisResult(response?.data?.data?.ai_assistant_response_of_audio);
       })
       .catch((error) => {
         setIsLoading(false);
         if (error?.response?.data?.error?.length > 0) {
           setErrorMsg(error?.response?.data?.error);
         } else {
           setErrorMsg("Something wents wrong");
         }
         console.log("🚀 ~ axios.post ~ error:", error);
       });
     setShowModal(true);
   };
   const handelCloseModal = () => {
     setShowModal(false);
     setErrorMsg("");
     if (audioAnalysisResult?.length > 0) {
       setAudioUrl("");
       setVoice("");
       setAudioAnalysisResult("");
     }
    }
  return (
    <div className="voice-analyzer">
      {/* Header */}
      <div className="voice-analyzer-header">
        <p>Voice Deepfake Analyzer</p>
      </div>
      <div className="voice-analyzer-main">
        {/* choose content type 
        <div className="voice-analyzer-content">
          <span>Select content type</span>
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <div className="toggle-switch">
              <input
                className="toggle-input"
                id="toggle"
                type="checkbox"
                onChange={handelContentTypeChange}
              />
              <label className="toggle-label" htmlFor="toggle"></label>
            </div>
            {isMusic ? "Music" : "Audio"}
          </div>
        </div> */}
        {/* Audio Recording Section */}
          <div className="voice-analyzer-record">
            <p>Record audio</p>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <button
                className="btn"
                disabled={voice}
                onClick={
                  isRecording ? handleStopRecording : handleStartRecording
                }
              >
                {isRecording ? "Stop Recording" : "Start Recording"}
              </button>
              {isRecording && (
                <img
                  src={require("../../../assets/icons/icons8-audio-wave.gif")}
                  alt="music-gif"
                  width={37}
                  height={37}
                />
              )}
            </div>
            {audioUrl && (
              <div>
                <p style={{ fontWeight: 600 }}>Recorded Audio:</p>
                <audio controls src={audioUrl} />
              </div>
            )}
          </div>
        {/* Submiting Music File Section */}
          <div>
            <div className="voice-analyzer-file">
              <p>Choose music file and content type</p>
              <input
                type="file"
                accept=".mp3,.wav,audio/*"
                id="upload"
                hidden
                disabled={audioUrl?.length > 0 ? true : false}
                onChange={handelFileChange}
                onClick={(e) => {
                  e.target.value = "";
                  setFormData("");
                  setVoice("");
                }}
              />
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <label
                  htmlFor="upload"
                  className="btn"
                  style={{ 
                    textWrap: "nowrap", 
                    backgroundColor: isRecording || audioUrl?.length > 0 ? "#b1b4b3" : "", 
                    cursor: isRecording || audioUrl?.length > 0 ? "not-allowed" : "pointer"
                  }}
                >
                  Browse File
                </label>
                <div>
                  <select
                    disabled={isRecording || audioUrl?.length > 0 ? true : false}
                    className="content-type"
                    onChange={(e) => {
                      setContentType(e.target.value);
                    }}
                  >
                    <option value={"music"}>Music</option>
                    <option value={"voice"}>Audio</option>
                  </select>
                </div>
              </div>
            </div>
            {voice?.length > 0 ? (
              <div className="file">
                <div style={{ width: "1.5rem" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="#016b38"
                      d="M499.1 6.3c8.1 6 12.9 15.6 12.9 25.7l0 72 0 264c0 44.2-43 80-96 80s-96-35.8-96-80s43-80 96-80c11.2 0 22 1.6 32 4.6L448 147 192 223.8 192 432c0 44.2-43 80-96 80s-96-35.8-96-80s43-80 96-80c11.2 0 22 1.6 32 4.6L128 200l0-72c0-14.1 9.3-26.6 22.8-30.7l320-96c9.7-2.9 20.2-1.1 28.3 5z"
                    />
                  </svg>
                </div>
                <span>{voice}</span>
              </div>
            ) : (
              isBigFile && <span style={{ color: "red" }}>File is to big</span>
            )}
          </div>
        {/* Upload Audio or Music File */}
        <div className="voice-analyzer-upload">
          <button
            className="btn"
            disabled={voice?.length > 0 || audioUrl?.length > 0 ? false : true}
            onClick={handelUploadAudio}
          >
            Upload Audio
          </button>
        </div>
      </div>
      {showModal && (
        <Modal
          audioAnalysisResult={audioAnalysisResult}
          isLoading={isLoading}
          errorMsg={errorMsg}
          onClick={handelCloseModal}
        />
      )}

    </div>
  );
};

export default AudioAnalyzer;
