import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../panels/Authentication/Login";
import SignUp from "../panels/Authentication/SignUp";
import { useIsAuthenticatedUser } from "../utils/util";
import IntegrationsContent from "../panels/UserPanel/Integrations";
import UserEngagementContent from "../panels/UserPanel/UserEngagement";
import AudioAnalyzer from "../panels/UserPanel/AudioAnalyzer";
import HomeContent from "../panels/UserPanel/Home";
import Layout from "../components/shared/Layout";
import Alerts from "../panels/UserPanel/Alerts";
import SurveysContent from '../panels/UserPanel/HerdSurveys';

const Router = () => {
  console.log('useIsAuthenticatedUser()', useIsAuthenticatedUser())
  return (
    <Routes>
      <Route
        path="*"
        element={
          useIsAuthenticatedUser() ? (
            <Navigate to={'/home'} />
          ) : (
            <Navigate to={"/login"} />
          )
        }
      />
      {useIsAuthenticatedUser() ? (
        <Route>
          <Route path="/home" element={<Layout><div id="content"><HomeContent /></div></Layout>} />
          <Route path="/alerts" element={<Layout><div id="content"><Alerts /></div></Layout>} />
          <Route path="/integrations" element={<Layout><div id="content"><IntegrationsContent /></div></Layout>} />
          <Route path="/users" element={<Layout><div id="content"><UserEngagementContent /></div></Layout>} />
          <Route path="/surveys" element={<div id="content"><SurveysContent /></div>} />
          <Route path="/audio-analyzer" element={<Layout><div id="content"><AudioAnalyzer /></div></Layout>} />
        </Route>
      ) :
        <Route>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
        </Route>
      }
    </Routes>
  );
};

export default Router;
