import React, { useEffect, useRef } from "react";

const AmazonConnectCCP = () => {
    const containerDivId = "container-div";
    const instanceURL = "https://herdsectestingmin.my.connect.aws/ccp-v2/";
    const streamsCdnUrl = "https://cdn.jsdelivr.net/npm/amazon-connect-streams/release/connect-streams-min.js";

    const isCCPInitialized = useRef(false);

    const setupAgentEventHandlers = () => {
        console.log("Setting up agent event handlers...");
        window.connect.agent(agent => {
            agent.onRefresh(() => {
                try {
                    const state = agent.getState().name;
                    console.log(`Agent state updated: ${state}`);

                    const agentData = agent.getAgentData();
                    console.log("Agent data:", agentData);

                    const resourceId = agentData.configuration.resourceId;
                    console.log("Agent Resource ID:", resourceId);
                } catch (error) {
                    console.error("Error fetching agent data:", error);
                }
            });
        });
    };

    const setupContactEventHandlers = () => {
        console.log("Setting up contact event handlers...");
        window.connect.contact(contact => {
            contact.onIncoming(() => {
                console.log("Incoming call detected.");
                alert("You have an incoming call!");
            });

            contact.onAccepted(() => {
                console.log("Call accepted by agent.");
            });

            contact.onConnected(() => {
                console.log("Call is connected. Conversation started.");
                alert("Call started with the customer.");
            });

            contact.onEnded(() => {
                console.log("Call ended.");
                alert("Call ended.");
            });
        });
    };

    useEffect(() => {
        if (isCCPInitialized.current) {
            console.log("CCP is already initialized.");
            return;
        }

        const script = document.createElement("script");
        script.src = streamsCdnUrl;
        script.type = "text/javascript";

        script.onload = () => {
            try {
                const container = document.getElementById(containerDivId);
                if (!container) {
                    console.error("Container div not found!");
                    return;
                }

                window.connect.core.initCCP(container, {
                    ccpUrl: instanceURL,            // REQUIRED
                    loginPopup: true,               // optional, defaults to `true`
                    loginPopupAutoClose: true,      // optional, defaults to `false`
                    loginOptions: {                 // optional, if provided opens login in new window
                        autoClose: true,              // optional, defaults to `false`
                        height: 600,                  // optional, defaults to 578
                        width: 400,                   // optional, defaults to 433
                        top: 0,                       // optional, defaults to 0
                        left: 0                       // optional, defaults to 0
                    },
                    region: 'us-west-2', // REQUIRED for `CHAT`, optional otherwise
                    softphone: {
                        // optional, defaults below apply if not provided
                        allowFramedSoftphone: true, // optional, defaults to false
                        disableRingtone: false, // optional, defaults to false
                        ringtoneUrl: '[your-ringtone-filepath].mp3', // optional, defaults to CCP’s default ringtone if a falsy value is set
                        disableEchoCancellation: false, // optional, defaults to false
                        allowFramedVideoCall: true, // optional, default to false
                        allowFramedScreenSharing: true, // optional, default to false
                        allowFramedScreenSharingPopUp: true, // optional, default to false
                        VDIPlatform: null, // optional, provide with 'CITRIX' if using Citrix VDI, or use enum VDIPlatformType
                        allowEarlyGum: true, //optional, default to true
                    },
                    task: {
                        disableRingtone: false, // optional, defaults to false
                        ringtoneUrl: "[your-ringtone-filepath].mp3" // optional, defaults to CCP's default ringtone if a falsy value is set
                    },
                    pageOptions: { //optional
                        enableAudioDeviceSettings: false, //optional, defaults to 'false'
                        enableVideoDeviceSettings: false, //optional, defaults to 'false'
                        enablePhoneTypeSettings: true //optional, defaults to 'true' 
                    },
                    shouldAddNamespaceToLogs: false, //optional, defaults to 'false'
                    ccpAckTimeout: 5000, //optional, defaults to 3000 (ms)
                    ccpSynTimeout: 3000, //optional, defaults to 1000 (ms)
                    ccpLoadTimeout: 10000 //optional, defaults to 5000 (ms)
                });

                setupAgentEventHandlers();
                setupContactEventHandlers();

                console.log("CCP initialized successfully.");
                isCCPInitialized.current = true;
            } catch (error) {
                console.error("Error initializing CCP:", error);
            }
        };

        script.onerror = error => {
            console.error("Failed to load the CCP script:", error);
        };

        document.body.appendChild(script);

        return () => {
            if (script.parentNode) {
                script.parentNode.removeChild(script);
            }
        };
    }, []);

    return (
        <div id={containerDivId} style={{ height: "500px", border: "1px solid #ccc" }}>
        </div>
    );
};

export default AmazonConnectCCP;
