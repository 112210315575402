import React, { useState } from 'react';
import Android from "../../../assets/icons/Android";
import IOS from "../../../assets/icons/IOS";
import Amazon from "../../../assets/icons/Amazon";
import Herd from "../../../assets/icons/Herd";
import AmazonConnectCCP from '../../../components/AmazonConnectCCP';
import ChangeTextColor from '../../../components/shared/ChangeColorText';

const Modal = ({ isLoading, setShowModal }) => {
    const [responseMessage, setResponseMessage] = useState('');
    return (
        <>
            <div className='h-full w-full justify-center items-center flex overflow-hidden fixed inset-0 z-50 outline-none focus:outline-none'>
                <div className='relative w-auto my-6 mx-auto max-w-4xl'>
                    {/*content*/}
                    <div className='px-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                        <div className='flex w-full justify-between items-center !mr-auto pt-2' onClick={() => {
                            try {
                                const containerDiv = document.getElementById("containerDiv");
                                if (containerDiv) {
                                    setShowModal(false)
                                    containerDiv.innerHTML = "";
                                    window.location.reload();
                                    // window.connect.core.terminate();
                                }
                            } catch (error) {
                                console.error("Error terminating Amazon Connect session:", error);
                                setShowModal(false)
                            }
                        }}>
                            <span className='!text-black font-bold text-2xl'>Amazon Connect</span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="!h-6 !w-6 cursor-pointer text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        {/*body*/}
                        <div className='flex gap-2 relative py-2 h-[calc(100vh-180px)] w-full overflow-auto'>
                            <div className='h-full w-[550px] overflow-auto'>
                                <AmazonConnectCCP setResponseMessage={setResponseMessage} />
                            </div>
                            {responseMessage?.length > 0 && <div className='h-[calc(100%-8px)] w-[250px] overflow-auto'>
                                <div className='text-black font-semibold text-xl'>Deepfake Analyzer</div>
                                <ChangeTextColor responseMessage={responseMessage} />
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
    );
};

const IntegrationsContent = () => {
    const [showModal, setShowModal] = useState(false);

    return (
        <div className={`${showModal ? 'tailwind-enabled' : ''}`}>
            <div id="inteContent" className={`${showModal ? 'overflow-hidden h-[calc(100vh-200px)]' : ''}`}>
                <h1>Integrations</h1>
                <h3>Mobile Devices</h3>
                <div className="flexcontainer">
                    <div className="inteContainer">
                        <div className="leftcontent">
                            <Android style={{ minWidth: '70px', minHeight: "70px" }} />
                            <div className="stack">
                                <h5>Android </h5>
                                <p>Monitor Android Phones For Voice Fraud </p>
                            </div>
                        </div>
                        <button className="install">Install Plugin</button>
                    </div>

                    <div className="inteContainer">
                        <div className="leftcontent">
                            <IOS style={{ minWidth: '70px', minHeight: "70px" }} />
                            <div className="stack">
                                <h5>iOS</h5>
                                <p>Monitor iPhone’s for Voice Fraud</p>
                            </div>
                        </div>
                        <button className="install">Install Plugin</button>
                    </div>
                </div>
                <h3>VoIP Systems</h3>
                <div className="flexcontainer">
                    <div className="inteContainer">
                        <div className="leftcontent">
                            <Amazon style={{ minWidth: '70px', minHeight: "70px" }} />
                            <div className="stack">
                                <h5>AWS Connect</h5>
                                <p>Monitor Inbound Calls Through AWS Connect.</p>
                            </div>
                        </div>
                        <button className="install" onClick={() => setShowModal(true)}>Install Plugin</button>
                    </div>
                </div>
                <h3>API </h3>
                <div className="flexcontainer">
                    <div className="inteContainer">
                        <div className="leftcontent">
                            <Herd style={{ minWidth: '70px', minHeight: "70px" }} />
                            <div className="stack">
                                <h5>Herd API</h5>
                                <p>Access our API To Integrate With Your Own Tools</p>
                            </div>
                        </div>
                        <button className="install">Install Plugin</button>
                    </div>
                </div>
                {showModal && (
                    <Modal
                        isLoading={false}
                        setShowModal={setShowModal}
                    />
                )}
            </div>
        </div>
    );
}
export default IntegrationsContent;
