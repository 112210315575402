export default function ResponseComponent({ responseMessage }) {
    // Extract percentage from the string
    const match = responseMessage.match(/(\d+(\.\d+)?)%/);
    const percentage = match ? match[0] : ""; // "99.74%"

    // Function to determine Tailwind color class
    const getPercentageColor = (percentage) => {
        const value = parseFloat(percentage);
        if (value > 70) return "text-red-500";
        if (value < 30) return "text-green-500";
        return "text-yellow-500";
    };

    const textColor = percentage ? getPercentageColor(percentage) : "text-black";

    return (
        <p>
            {responseMessage.replace(percentage, "")} {/* Normal text */}
            <span className={textColor}>{percentage}</span> {/* Colored percentage */}
        </p>
    );
}